import MessageBody from "Components/MessageBody";
import StudentRenderer from "Components/ModelRenderers/StudentRenderer";
import MyBrandFooter from "Components/MyBrandFooter";
import { MyTable } from "Components/MyTable";
import RaidTerrainImage from "Components/RaidTerrainImage";
import YoutubeEmbed from "Components/YoutubeEmbed";
import IStyle from "Interfaces/IStyle";
import { ITableRow } from "Interfaces/ITableRow";
import Student from "Models/Student";
import { copyToClipboard } from "Utils/Common";
import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";


const styles:IStyle = {
	scrollable: {
		overflowY: 'scroll',
	},
	container: {
		padding: 30,
		margin: 10,
		border: "1px solid",
		// borderColor: "violet",
		borderRadius: 20,
		borderColor: "pink",
	}
}

class QuickLink implements ITableRow {
	name:string;
	link:string;
	note:string;
	constructor(name:string, link:string, note?:string) {
		this.name = name;
		this.link = link;
		this.note = note ?? "";
	}
	getHeaders () { return [
		// "Name", 
		"Link", 
		// "Note"
	]; } 
	toArray () { return [
		// this.name,
		this.link,
		// this.note,
	] };
	toKeyValues () { return {
		// Name: this.name,
		Link: this.link,
		// Note: this.note,
	 } };
}

const QUICKLINKS = [
	new QuickLink("Student Insights", "https://hina.loves.midokuni.com/StudentInsights"),
	new QuickLink("Student Insights - Specific", "https://hina.loves.midokuni.com/StudentInsights/Hina"),
	new QuickLink("Raid Data", "https://hina.loves.midokuni.com/Raid"),
	new QuickLink("Banner Summary", "https://hina.loves.midokuni.com/Summary/Banner"),
	new QuickLink("PVP Analyzer", "https://hina.loves.midokuni.com/Tool/PVP"),
	new QuickLink("StudentEXp", "https://hina.loves.midokuni.com/Tool/StudentEXP"),
	new QuickLink("Spark Tracker", "https://hina.loves.midokuni.com/Tool/Spark"),
	new QuickLink("Banner Sheet", "https://hina.loves.midokuni.com/doc/Banner"),
	new QuickLink("Banner Sheet - Specific Student", "https://hina.loves.midokuni.com/doc/Banner/Hina"),
	new QuickLink("", "https://hina.loves.midokuni.com/doc/Banner/Next"),
	new QuickLink("", "https://hina.loves.midokuni.com/doc/BannerSummary"),
	new QuickLink("", "https://hina.loves.midokuni.com/doc/GeneralTeamBuilding"),
	new QuickLink("", "https://hina.loves.midokuni.com/doc/GeneralTeamBuilding/Red"),
	new QuickLink("", "https://hina.loves.midokuni.com/doc/GeneralTeamBuilding/Blue"),
	new QuickLink("", "https://hina.loves.midokuni.com/doc/GeneralTeamBuilding/Yellow"),
	new QuickLink("", "https://hina.loves.midokuni.com/doc/GeneralTeamBuilding/Etc"),
	new QuickLink("", "https://hina.loves.midokuni.com/doc/Glossary"),
	new QuickLink("", "https://hina.loves.midokuni.com/doc/Glossary/Hina"),
	new QuickLink("", "https://hina.loves.midokuni.com/doc/Farmable"),
	new QuickLink("", "https://hina.loves.midokuni.com/doc/Farmable/Hina"),
	new QuickLink("", "https://hina.loves.midokuni.com/doc/FarmableSummary"),
	new QuickLink("", "https://hina.loves.midokuni.com/doc/HardSummary"),
	new QuickLink("", "https://hina.loves.midokuni.com/doc/Borrow"),
	new QuickLink("", "https://hina.loves.midokuni.com/doc/Reroll"),
	new QuickLink("", "https://hina.loves.midokuni.com/doc/Reroll/Reroll"),
	new QuickLink("", "https://hina.loves.midokuni.com/doc/Reroll/Essentials"),
	new QuickLink("", "https://hina.loves.midokuni.com/doc/Reroll/Basics"),
	new QuickLink("", "https://i.imgur.com/Gc8XBfc.jpg"),
]
export default function Home() {
	
	function copyMe(e:any) {
		copyToClipboard(e.target.innerText).then(()=>{
			alert("Copied to clipboard!\n"+e.target.innerText);
		})
	}
	const HINA = useMemo(() => Student.fromName("Hina"), []);
	const SHINA = useMemo(() => Student.fromName("Hina (Swimsuit)"), []);
	const DHINA = useMemo(() => Student.fromName("Hina (Dress)"), []);
	return (
		<Row>
			<Col>
				<Row>
					<Col
						className="home-container" 
						style={styles.scrollable}
					>
						<Row>
							<Col className="bg-violet-transparent-05" style={styles.container}>
								<Row><Col>
								<h1 className="text-pink">@Home: Hina Loves Midokuni</h1>
								</Col></Row>
								<Row>
									<Col>
										<StudentRenderer bare link="/StudentInsights/%id%" compact={true} model={HINA} />
									</Col>
									<Col>
										<StudentRenderer bare link="/StudentInsights/%id%" compact={true} model={SHINA} />
									</Col>
									<Col>
										<StudentRenderer bare link="/StudentInsights/%id%" compact={true} model={DHINA} />
									</Col>
								</Row>
								<Row>
									<Col>
										<p>Welcome to Hina Loves Midokuni. I don't know how to summarize this as anything other than "Blue Archive Things." Enjoy.</p>
										<p>
											If you're here, chances are that you're looking for one of the most popular pages in this website:
											<ol>
												<li><Link to="/Summary/Banner">Banner Summary</Link></li>
												<li><Link to="/StudentInsights">Student Insights</Link></li>
												<li><Link to="/Resources">Resources</Link></li>
											</ol>
										</p>
										<p>
											If not, then you're probably gonna find it in the Navigation Bar above
										</p>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col className="bg-violet-transparent-05" style={styles.container}>
								<Row>
									<Col><h2 className="text-pink">Bulletin Board</h2></Col>
								</Row>
								<Row>
									<Col>
										<RaidTerrainImage name={"Hod"} terrain="Street">
											<Row>
												<Col>
													<h3 className="text-pink">GA Tourney Season 4 - Hod</h3>
												</Col>
											</Row>
											<hr></hr>
											<Row>
												<Col>
													<Row>
														<Col>
															<p>
															<a href="https://www.youtube.com/@causew">Causew</a> is hosting a Grand Assault Tournament. Details at: <a href="https://discord.gg/dH4MQvbHF7">DISCORD</a>
															</p>
															<p>
																When I get to it, lineup history will be in: <a href="/GrandRaid/TOURNAMENT/4">TOURNAMENT</a>
															</p>
														</Col>
													</Row>
													<Row>
														<Col>
														I forgor. Moving forward perhaps I'll just put a link to the Discord server
														</Col>
													</Row>
													{/* <Row>
														<Col>
															<h4 className="text-pink">Group Stage</h4>
															<YoutubeEmbed src="https://www.youtube.com/embed/kC1fI1He_2I?si=k2yabN3Bhtb7M08w"></YoutubeEmbed>
														</Col>
													</Row>
													<Row>
														<Col>
															<h4 className="text-pink">Finals</h4>
															<YoutubeEmbed src="https://www.youtube.com/embed/uAkJxmZadCU?si=DdOtgBiQZdGhzceH"></YoutubeEmbed>
														</Col>
													</Row> */}
												</Col>
											</Row>
										</RaidTerrainImage>
									</Col>
								</Row>
								<hr></hr>
								<Row>
									<Col>
										<RaidTerrainImage name={"Kaiten"} terrain="Field">
											<Row>
												<Col>
													<h3 className="text-pink">GA Tourney Season 3 - Kaiten</h3>
												</Col>
											</Row>
											<hr></hr>
											<Row>
												<Col>
													<Row>
														<Col>
															<p>
																<a href="https://www.youtube.com/channel/UC9e84Y0yMMZygoapjlSy73w">tomogumodu</a> is hosting a Grand Assault Tournament. Details at: <a href="https://youtu.be/SZBGtdZ-g1o">https://youtu.be/SZBGtdZ-g1o</a>
															</p>
															<p>
																I've added lineup history here: <a href="/GrandRaid/TOURNAMENT/3">TOURNAMENT</a>
															</p>
														</Col>
													</Row>
													<Row>
														<Col>
															<h4 className="text-pink">Group Stage</h4>
															<YoutubeEmbed src="https://www.youtube.com/embed/kC1fI1He_2I?si=k2yabN3Bhtb7M08w"></YoutubeEmbed>
														</Col>
													</Row>
													<Row>
														<Col>
															<h4 className="text-pink">Finals</h4>
															<YoutubeEmbed src="https://www.youtube.com/embed/uAkJxmZadCU?si=DdOtgBiQZdGhzceH"></YoutubeEmbed>
														</Col>
													</Row>
												</Col>
											</Row>
										</RaidTerrainImage>
									</Col>
								</Row>
								<hr></hr>
								<Row>
									<Col>
										A bit late but the 2024 April 20 JP Stream Summary: <a href="https://imgur.com/a/zbXL6UY">https://imgur.com/a/zbXL6UY</a>
									</Col>
								</Row>
								<hr></hr>
								<Row>
									<Col>
										<RaidTerrainImage name={"ShiroKuro"} terrain="Indoor">
											<Row>
												<Col>
													<h3 className="text-pink">GA Tourney Season 2 - Shirokuro</h3>
												</Col>
											</Row>
											<hr></hr>
											<Row>
												<Col>
													<p>
														<a href="https://www.youtube.com/@causew">Causew</a> is hosting a Grand Assault Tournament. Details at: <a href="https://forms.gle/5w2L2QiAeg4swSGW6">https://forms.gle/5w2L2QiAeg4swSGW6</a>
													</p>
													<h4 className="text-pink">Stream Links</h4>
													<ul>
														<li>
															Day 1 Groups - <a href="https://youtube.com/live/EztZxPWHitY">https://youtube.com/live/EztZxPWHitY</a>
														</li>
														<li>
															Day 2 Elimns - <a href="https://youtube.com/live/C3JYwafuw10">https://youtube.com/live/C3JYwafuw10</a>
														</li>
													</ul>
													<p>
														I've added lineup history here: <a href="/GrandRaid/TOURNAMENT/2">TOURNAMENT</a>
													</p>
												</Col>
											</Row>
										</RaidTerrainImage>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col className="bg-violet-transparent-05" style={styles.container}>
								<h2 className="text-pink">Quick Links</h2>
								Click a cell to copy
								<MyTable onCellClick={copyMe} variant="violet" data={QUICKLINKS}></MyTable>
							</Col>
						</Row>
					</Col>
					<Col  
					className="home-container bg-violet-transparent-05" 
					style={{...styles.container, ...styles.scrollable}}
					xs={12} md={4} lg={5} xl={4}>
						<MessageBody />
					</Col>
				</Row>
				<MyBrandFooter />
			</Col>
		</Row>
	)
}