import RaidTerrainImage from "Components/RaidTerrainImage";
import { TagBadge } from "Components/TagBadge";
import { IJustinTournamentData } from "Interfaces/IJustinTournamentData";
import { getJson } from "Utils/Common";
import GrandTournament from "Views/Tools/GrandTournament";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Card, Col, Row, Table } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import { useParams } from "react-router-dom";

const LSTORAGE_ROOT = "midokuni.grand.tournament.";
const handleFocus = (event:React.ChangeEvent<HTMLInputElement>) => {
	event.target.select();
}
interface IRaidInfo {
	season: number,
	playable: {[name:string]: any},
	start: string,
	end: string,
	seasonType: any
}
interface IListItemProps {
	data: IJustinTournamentData,
	isActive?: boolean,
}
interface IStanding {
	name: string;
	score: {
		light: number;
		heavy: number;
		special: number;
		elastic: number;
	};
	rank?: {
		light: number;
		heavy: number;
		special: number;
		elastic: number;
	},
	overallRank: number;
}
export default function GrandTournamentList() {
	const [justinPassword, setJustinPassword] = useState(localStorage.getItem(LSTORAGE_ROOT+"justin.password") ?? "");
	const [info, setInfo] = useState<IRaidInfo>({
		season: -1,
		playable: { unknown: 0 },
		start: "0",
		end: "0",
		seasonType: -1
	});
	const {season} = useParams();
	const [list, setList] = useState<IJustinTournamentData[]>([]);
	const [standing, setStanding] = useState<IStanding[]>([]);
	const [active, setActive] = useState(-1);

	const keys = Object.keys(info.playable);
	const raidName = keys[0]?.split("_")[0] ?? "";
	
	useEffect(() => {
		let ignore = false;
		const root = `/files/data/grandRaid/tournament/${season.toString()}`;
		async function loadInfo() {
			const result = await getJson(`${root}/info.json`);
			if (!ignore) {
				setInfo(result);
			}
		}

		loadInfo();

		return () => {
			ignore = true
		}
	}, [season])
	async function getJustin() {
		const url = "https://api.justin163.com/loadtourneydata";
		const rawResponse = await fetch(url, {
			method: 'GET',
			headers: new Headers({
				'TournamentPassword': justinPassword
			}),
		});
		const data = await rawResponse.json();
		switch (rawResponse.status) {
			case 200:
				const tdata:IJustinTournamentData[] = JSON.parse(data.Data).map((d:string) => JSON.parse(d));
				const filtered = tdata.filter(d=>d.Tournament === `tournament-${season}`)
					.sort((a,b) => a.Game.localeCompare(b.Game))
					.sort((a,b) => ([a.Player1, a.Player2].sort().join("_")).localeCompare([b.Player1, b.Player2].sort().join("_")))
					// .sort((a,b) => a.Player2.localeCompare(b.Player2))
					.sort((a,b) => a.Stage.localeCompare(b.Stage))
				setList(filtered);
				setActive(-1);
				const quals = filtered.filter(d=>d.Stage === "qualifiers")
				const standings:IStanding[] = []
				for(const player of [...new Set(quals.map(d=>d.Player1))]) {
					const pData:IStanding = {
						name: player,
						score: {
							light: 0,
							heavy: 0,
							special: 0,
							elastic: 0,
						},
						rank: {
							light: 0,
							heavy: 0,
							special: 0,
							elastic: 0,
						},
						overallRank: 0
					}
					for (const game of quals.filter(d=>d.Player1 === player)) {
						pData.score[game.Armour] = parseInt(game.Player1Score.replaceAll(",",""))
					}
					standings.push(pData);
				}
				if (standings.length > 0) {
					for (const arm of ["light", "heavy", "special", "elastic"]) {
						standings.sort((a,b) => b.score[arm] - a.score[arm]);
						// no one got any score for this armor... everyone is equal then
						if (standings[0].score[arm] === 0) continue;
						for (let i=0; i<standings.length; i++) {
							if (i > 0 && standings[i].score[arm] === standings[i-1].score[arm])
								standings[i].rank[arm] = standings[i-1].rank[arm]
							else
								standings[i].rank[arm] = i+1;
							standings[i].overallRank += standings[i].rank[arm];
						}
					}

					standings.sort((a,b) => a.overallRank - b.overallRank);
				}
				setStanding(standings);

				break;
			default:
				alert(`Error happened while getting Justin163 data:\n\n${data.Error}`);
				break;
		}
	}

	function ListItem(props: IListItemProps) {
		const {data, isActive} = props;
		const p1Score = parseInt(data.Player1Score.replaceAll(/[^\d]/g, ""))
		const p2Score = parseInt(data.Player2Score.replaceAll(/[^\d]/g, ""))
		const winner = p1Score > p2Score ? data.Player1 : p1Score === p2Score ? "Same Score" : data.Player2;
		const isQualifiers = data.Stage === "qualifiers";
		// const [isActive, setIsActive] = useState(false);
		if (isActive)
			return (<Card>
				<Card.Body>
					<Card.Text><strong>{data.Stage.toLocaleUpperCase()}</strong>: {data.Player1} {isQualifiers ? "" : `vs ${data.Player2}`} / Game {data.Game.substring(5)}: <TagBadge>{data.Armour}</TagBadge> Armor {isQualifiers ? "" : `Winner: ${winner}`}</Card.Text>
				</Card.Body>
			</Card>);
		
		return (<>
			<td width="0">{data.Stage.toLocaleUpperCase()}</td>
			<td width="0">{data.Player1} {isQualifiers ? "" : `vs ${data.Player2}`}</td>
			<td width="0">{data.Game.substring(5)}</td>
			<td width="0"><TagBadge>{data.Armour}</TagBadge></td>
			<td width="0">{data.Player1Score} {isQualifiers ? "" : `vs ${data.Player2Score}`}</td>
			<td width="0">{isQualifiers ? null : `${winner}`}</td>
		</>);
	}

	return (
		<>
		<Row>
			<Col>
				<RaidTerrainImage name={raidName} terrain={keys[0]?.split("_")[1] ?? ""} >
					<Row>
						<Col xs={2}><label>Justin Password</label></Col>
						<Col>
							<input type="password" value={justinPassword} onFocus={handleFocus} onChange={(e) => {
								localStorage.setItem(LSTORAGE_ROOT+"justin.password", e.target.value);
								setJustinPassword(e.target.value)
							}}></input>
						</Col>
					</Row>
					<Row><Col><Button variant="violet" onClick={getJustin}>Reload Data</Button></Col></Row>
					<Row><Col><Accordion defaultActiveKey="0">
						<AccordionItem eventKey="0">
							<AccordionHeader>Qualifier Standings</AccordionHeader>
							<AccordionBody>
								{standing.length > 0 ? (<Table responsive variant="violet" striped hover>
									<thead><tr>
										<th>Rank</th>
										<th>Player</th>
										<th>Total</th>
										<th><TagBadge>Light</TagBadge></th>
										<th><TagBadge>Heavy</TagBadge></th>
										<th><TagBadge>Special</TagBadge></th>
										<th><TagBadge>Elastic</TagBadge></th>
									</tr></thead>
									<tbody>
										{standing.map((s, i) => {
											return (<tr>
												<td width="0"><strong>{i+1}</strong></td>
												<td width="0"><strong>{s.name}</strong></td>
												<td width="0"><strong>{s.overallRank}</strong></td>
												<td width="0"><strong>{s.rank.light}</strong></td>
												<td width="0"><strong>{s.rank.heavy}</strong></td>
												<td width="0"><strong>{s.rank.special}</strong></td>
												<td width="0"><strong>{s.rank.elastic}</strong></td>
											</tr>)
										})}
									</tbody>
								</Table>) : "No Data"}
							</AccordionBody>
						</AccordionItem>
					</Accordion></Col></Row>
					{active < 0 ? null : <Row>
						<Col>
							<Row>
								<Col>Currently Updating:</Col>
							</Row>
							<Row><Col><ListItem isActive data={list[active]} /></Col></Row>
						</Col>
						<Row><Col><Button variant="violet" onClick={()=>setActive(-1)}>Go Back</Button></Col></Row>
					</Row>}
					
				</RaidTerrainImage>
			</Col>
		</Row>
		{active < 0 ? <Row>
			<Col>
			<Table responsive variant="violet" striped hover>
				<thead>
				</thead>
				<tbody>
					<tr>
						<td width="0"><strong>Details</strong></td>
						<td width="0"><strong>Stage</strong></td>
						<td width="0"><strong>Match</strong></td>
						<td width="0"><strong>Game</strong></td>
						<td width="0"><strong>Armor</strong></td>
						<td width="0"><strong>Score</strong></td>
						<td width="0"><strong>Winner</strong></td>
					</tr>
					{list.map((d, i) => (<tr key={`${d.Tournament}_${d.Stage}_${d.Armour}_${d.Player1}_${d.Player2}`}>
						<td style={{textAlign: "right", verticalAlign: "center", width: 0}}><Button variant="violet" onClick={()=>setActive(i)}>View/Update</Button></td>
						<ListItem data={d} />
					</tr>))}
				</tbody>
			</Table>
			</Col>
		</Row> : <GrandTournament template={list[active]} />}
		</>
	)
}