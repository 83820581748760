import { TagBadge } from "Components/TagBadge";
import IParentProps from "Interfaces/Props/IParentProps";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

export default function RaidTerrainImage(props: {devName?:string, name:string, terrain?: string} & IParentProps) {
	let {devName, name, terrain} = props;
	if (!!!devName || !!!devName.length) {
		switch (name) {
			case "Kaiten":
			case "Kaitenger":
				devName = 'KaitenFxMk0';
				break;
			case "HOD":
				devName = "Hod"
				break;
			case "Perorozilla":
				devName = "Perorodzilla"
				break;
			case "EN0005":
				devName = "Gregorius"
				name = devName;
				break;
			case "EN0008":
				devName = "Set"
				name = devName;
				break;
			case "HoverCraft":
				devName = "Hovercraft";
				name = devName;
			default:
				devName = name;
		}
	}
	if (!!!terrain || !!!terrain.length) {
		//  Default Terrains
		switch (devName) {
			case "Binah":
			case "Hovercraft":
			case "KaitenFxMk0":
				terrain = "Field";
				break;
			case "Chesed":
			case "Goz":
			case "Gregorius":
				terrain = "Indoor";
				break;
			case "Kurokage":
			case "ShiroKuro":
				terrain = "Street";
				break;
		}
	}
	switch (terrain) {
		case "Outdoor":
			terrain = "Field";
			break;
		case "Urban":
			terrain = "Street";
			break;
		case "Indoors":
			terrain = "Indoor";
			break;
	}
	return (				
	<Card border="dark">
		<Card.Body>
			<Row>
				<Col md={12} className="d-block d-md-none">
					<Card.Img 
					
					// style={{maxWidth: 480}} 
					variant="bottom" src={`/img/Boss_Portrait_${devName}_Lobby.png`} />
				</Col>
				<Col>
					{props.children}
				</Col>
				<Col className="d-none d-md-block">
					<Card.Img 
					
					// style={{maxWidth: 480}} 
					variant="bottom" src={`/img/Boss_Portrait_${devName}_Lobby.png`} />
				</Col>
			</Row>
			{/* <Card.ImgOverlay> */}
			{/* </Card.ImgOverlay> */}
		</Card.Body>
		<Card.Footer><TagBadge>{terrain}</TagBadge>{name}</Card.Footer>
	</Card>
)
}