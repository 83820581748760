import { getJson } from "Utils/Common";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";

export function TowerFloorFileListDropDown(props: {
	onChange: React.ChangeEventHandler<HTMLSelectElement>;
	type?: string;
}) {
	const { server, season } = useParams();
	const type = props.type ?? "lbraid"
	const fileListPath = '/files/data/fileList.json';
	const [fileList, setFileList] = useState<string[]>([]);
	async function loadData() {
		let data = await getJson(fileListPath);
		data = data[type][server.toLocaleLowerCase()][season.toString()];
		if (!data)
			return; // Not valid. Show 404
		const files = {};
		const regexMatcher = /\d+-teams/g;
		for (const file in data) {
			const matches = file.match(regexMatcher);
			if (!!!matches)
				continue;
			files[matches[0]] = true;
		}

		setFileList(Object.keys(files).sort((a,b) => parseInt(a.split('-')[0]) - parseInt(b.split('-')[0])));
		// const temp = new RaidRankTsv();
		// setTsv(await temp.fromUrl(ranks));
	}
	useEffect(() => {
		loadData();
	}, []);

	return (
		<Form.Select onChange={props.onChange}>
			<option>Select Data Source</option>
			{fileList.map(file => {
				return (<option key={file} value={file}>{file}</option>);
			})}
		</Form.Select>
	);
}
