import { Images } from "Components/Images";
import { TagBadge } from "Components/TagBadge";
import IStyle from "Interfaces/IStyle";
import Student from "Models/Student";
import { isAprilFools } from "Utils/Common";
import React, { CSSProperties } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
const styles:IStyle = {
	imgCompact2: {
		clipPath: "inset(20% 0% 35% 0% round 0.3em 0.3em 0 0)",
		padding: 0,
		margin: "-23% 0% -39% 0%",
	},
	imgCompact: {
		clipPath: "inset(32% 0% 40% 0% round 0.3em 0.3em 0 0)",
		padding: 0,
		margin: "-35% 0% -43% 0%",
	},
	imgCompactOg: {
		clipPath: "inset(20% 20% 30% 20% round 0.5em)",
		padding: 0,
		margin: "-20% 0 -30% 0",
	},
	left: {paddingRight: "0.2em"},
	right: {paddingLeft: "0.2em"},
	noPadding: {
		padding: 0,
		margin: 0
	},
	assistBlock: {
		width: "100%",
		opacity: 0.8,
		padding: 2
	},
	footerOverlay: {
		width: "100%", 
		bottom:0, 
		position: "absolute"
	},
	link: {
		zIndex: 1
	},
	compactCard: {
		height:"auto", 
		whiteSpace: "nowrap",
	},
	studentCard: {
		height:"auto", 
		minWidth: 70,
	},
	glow: {
		boxShadow: "0 0 1em #ffafff",
	}
}
interface IProps {
	model:Student, 
	bare?:boolean, 
	alwaysFooter?: boolean, 
	compact?: boolean | number,
	link?: string
}

function compactImageStyle(type?: number | boolean): CSSProperties {
	let compactStyle = styles.imgCompact;
	if (typeof(type) === "number") {
		switch (type) {
			case 2: compactStyle = styles.imgCompact2; break;
		}
	}

	if (!isAprilFools()) {
		return compactStyle;
	}

	const [top, _left, bottom, _right] = compactStyle.clipPath.match(/\d+%/g).map(a=>parseInt(a))
	const [mtop, _mleft, mbottom, _mright] = compactStyle.margin.toString().match(/\d+%/g).map(a=>parseInt(a))
	const diffTop = top - mtop;
	const diffBot = bottom - mbottom;
	const size = 100 - (bottom + top);
	const maxPossible = 100+diffBot-size;
	const rng = Math.floor(Math.random()*maxPossible)
	// 100-(size+rng-diffBot) >= 0
	// 100 >= size+rng-diffBot
	// 100+diffBot-size >= rng

	return {
		...compactStyle,
		// position: "relative",
		// top: `${rng}%`,
		// transform: `translate(0, ${rng}%)`,
		// transformOrigin: `50% ${rng}% 0`
		clipPath: `inset(${rng}% 0% ${100-(size+rng)}% 0% round 0.3em 0.3em 0 0)`,
		margin: `-${rng-diffTop}% 0% -${100-(size+rng-diffBot)}% 0%`,
	};
}


export default function StudentRenderer(props: IProps) {
	const {model, compact} = props;
	const href = props.link ? props.link.replaceAll("%id%", model.getId().toString()) : undefined;
	function AssistBlock({isAssist}:{isAssist?: boolean}) {
		isAssist = typeof(isAssist) === "undefined" ? model.isAssist : isAssist; 
		if (!isAssist) return null;
		if (props.bare) return null;
		return (<Card.Header 
			className="text-center text-pink d-none d-sm-block bg-black"
			style={styles.assistBlock}
			>
			Assist
		</Card.Header>);
	}

	function TextLink({children, to}: {children: any, to?: string}) {
		if (!!!to) return children;

		return (
			<Link style={styles.link} className="text-white" target="_blank" to={to}>{children}</Link>
		)
	}
	
	function CompactStudentRenderer() {
		function UE() {
			if (props.bare) return null;
			if (!model.isUe) return <small style={styles.left}>
				{model.getTier()}
			</small>;
			if (model.ue > 0) {
				return (
					<span style={styles.left}>
						{model.ue}★<Images.UE />
					</span>
				);	
			}
			return (
				<span style={styles.left}>
					<Images.UE />
				</span>
			);
		}
		function Assist() {
			if (!model.isAssist) return null;
			if (props.bare) return null;
			return (
				<sup style={styles.right}>
					<TagBadge>A</TagBadge>
				</sup>
			)
		}
		const compactStyle = compactImageStyle(compact);
		return (
			<Card style={{...styles.compactCard, ...(model.isSkillSelected ? styles.glow : null)}}>
				<Card.Body style={styles.noPadding}>
					<TextLink to={href}><Card.Img variant="top" style={compactStyle} src={model.getIcon()} /></TextLink>
				</Card.Body>
				<Card.Footer style={{padding:0.2}}
					className="text-center bg-violet-transparent-70 text-white"
				>
					<UE />
					<TextLink to={href}>{model.getDisplayName()}</TextLink>
					<Assist />
				</Card.Footer>
			</Card>
		);
	}

	function Badges() {
		if (!!props.bare) return null;
		if (model.isUe && model.ue > 0) {
			return (
				<Card.Text>
					<TagBadge pretext={model.getUeTier()}>UE</TagBadge> <TagBadge pill>{model.getLevel()}</TagBadge>
				</Card.Text>
				);
		}
		return (
		<Card.Text>
			<TagBadge>{model.getTier()}</TagBadge> <TagBadge pill>{model.getLevel()}</TagBadge>
		</Card.Text>
		);
	}
	function FooterOverlay() {
		if (!!props.alwaysFooter) return null;
		return (
		<Card.Footer 
		className="text-center bg-violet-transparent-70 d-none d-lg-block text-white"
		style={styles.footerOverlay}
		>
			<TextLink to={href}>{model.getDisplayName()}</TextLink>
		</Card.Footer>
		);
	}
	
	if (compact) return <CompactStudentRenderer />;
	
	return (
		<Card style={{...styles.studentCard, ...(model.isSkillSelected ? styles.glow : null)}}>
			<TextLink to={href}><Card.Img variant="top" src={model.getIcon()} /></TextLink>
			<Card.ImgOverlay style={styles.noPadding}>
				
				<AssistBlock {...props} isAssist={!!!props.bare && model.isAssist} />
				<Card.Body style={styles.noPadding}>
					<Badges />
					<FooterOverlay />
				</Card.Body>
			</Card.ImgOverlay>
			<Card.Footer
			style={{padding:2}}
			className={props.alwaysFooter ? "text-center bg-violet-transparent-70 text-white" : "bg-violet-transparent-70 text-white text-center d-block d-lg-none"}
			>
				<TextLink to={href}>{model.getDisplayName()}</TextLink>
			</Card.Footer>
		</Card>
	);
}