import 'index.css';
import { Default } from 'Layouts/Default';
import StudentMap from 'Models/StudentMap';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
	createBrowserRouter,
	RouterProvider,
	useParams,
} from "react-router-dom";
import About from 'Views/About';
import BannerSummary from 'Views/BannerSummary';
import FarmableSummary from 'Views/FarmableSummary';
import Kivodle from 'Views/Fun/Kivodle';
import GrandRaidData from 'Views/GrandRaidData';
import HardSummary from 'Views/HardSummary';
import Home from 'Views/Home';
import PVPHistory from 'Views/PVPHistory';
import RaidData from 'Views/RaidData';
import RaidDataBridge from 'Views/RaidDataBridge';
import ResourceRepository from 'Views/ResourceRepository';
import StudentInsights from 'Views/StudentInsights';
import GrandTournament from 'Views/Tools/GrandTournament';
import GrandTournamentList from 'Views/Tools/GrandTournamentList';
import PVP from 'Views/Tools/PVP';
import Runner from 'Views/Tools/Runner';
import Spark from 'Views/Tools/Spark';
import SparkRate from 'Views/Tools/SparkRate';
import StudentExp from 'Views/Tools/StudentExp';
import TowerAssaultData from 'Views/TowerAssaultData';

const root = ReactDOM.createRoot(document.getElementById('root'));
const StudentInsightsWrapper = () => {
	const {studentParam} = useParams();

	return <Default name={"Midokuni's Student Insights - "+studentParam}><StudentInsights /></Default>
}

const RunnerWrapper = () => {
	const {template} = useParams();

	return <Default name={"Board Runner Event Simulator - " + template}><Runner template={template} /></Default>;
}
const RaidDataWrapper = () => {
	const {season, server} = useParams();

	return <Default name={`Raid Clear Data - ${server}/${season} `}><RaidData /></Default>;
}

const TowerDataWrapper = () => {
	const {season, server} = useParams();

	return <Default name={`Tower Clear Data - ${server}/${season} `}><TowerAssaultData /></Default>;
}

const GrandRaidDataWrapper = () => {
	const {season, server} = useParams();

	return <Default name={`Grand Assault Clear Data - ${server}/${season} `}><GrandRaidData /></Default>;
}
const GrandTournamentWrapper = () => {
	const {season} = useParams();

	return <Default name={`Grand Assault Tournament Referee Tool - ${season} `}><GrandTournament /></Default>;
}
const GrandTournamentListWrapper = () => {
	const {season} = useParams();

	return <Default name={`Grand Assault Tournament List Referee Tool - ${season} `}><GrandTournamentList /></Default>;
}


// TODO: /BannerSummary as reimplementation of upcoming banner summary
const router = createBrowserRouter([
	{
		path: "/",
		element: <Default hideFooter name="Hina Loves Midokuni"><Home /></Default>,
	},
	{
		path: "/Home",
		element: <Default hideFooter name="Hina Loves Midokuni"><Home /></Default>,
	},
	{
		path: "/About",
		element: <Default name="Contact Mido"><About /></Default>,
	},
	{
		path: "/Resources",
		element: <Default name="Blue Archive Resources, Guides, Tools, etc."><ResourceRepository /></Default>,
	},
	{
		path: "/StudentInsights",
		element: <Default name="Midokuni's Student Insights"><StudentInsights /></Default>,
	},
	{
		path: "/StudentInsights/:studentParam",
		element: <StudentInsightsWrapper />,
	},
	{
		path: "/Raid/:server/:season",
		element: <RaidDataWrapper />,
	},
	{
		path: "/Tower/:server/:season",
		element: <TowerDataWrapper />,
	},
	{
		path: "/Tower/:server",
		element: <Default name="Unleashed Assault Selection"><RaidDataBridge type="lbraid"/></Default>,
	},
	{
		path: "/Tower",
		element: <Default name="Unleashed Assault Selection"><RaidDataBridge type="lbraid"/></Default>,
	},
	{
		path: "/Raid/:server",
		element: <Default name="Total Assault Selection"><RaidDataBridge /></Default>,
	},
	{
		path: "/Raid",
		element: <Default name="Total Assault Selection"><RaidDataBridge /></Default>,
	},
	{
		path: "/GrandRaid/:server/:season",
		element: <GrandRaidDataWrapper />,
	},
	{
		path: "/GrandRaid/:server",
		element: <Default name="Grand Assault Selection"><RaidDataBridge type="grandRaid" /></Default>,
	},
	{
		path: "/GrandRaid",
		element: <Default name="Grand Assault Selection"><RaidDataBridge type="grandRaid" /></Default>,
	},
	{
		path: "/PVP",
		element: <Default name="PVP History"><PVPHistory/></Default>,
	},
	{
		path: "/PVP/:key",
		element: <Default name="PVP History"><PVPHistory/></Default>,
	},
	{
		path: "/Tool/PVP",
		element: <Default name="PVP Analyzer"><PVP /></Default>,
	},
	{
		path: "/Tool/Spark",
		element: <Default hideFooter name="Spark Tracker"><Spark /></Default>,
	},
	{
		path: "/Tool/StudentEXP",
		element: <Default name="Student Exp"><StudentExp /></Default>,
	},
	{
		path: "/Tool/Board/:template",
		element: <RunnerWrapper />,
	},
	{
		path: "/Tool/GrandTournament/:season",
		element: <GrandTournamentWrapper />,
	},
	{
		path: "/Tool/GrandTournamentList/:season",
		element: <GrandTournamentListWrapper />,
	},
	// {
	// 	path: "/Tool/Board",
	// 	element: <Default name={"Board Runner Event Simulator - Saki"}><Runner template={"Saki"} /></Default>
	// },
	{
		path: "/Summary/Banner",
		element: <Default name="Banner Summary"><BannerSummary /></Default>,
	},
	{
		path: "/Summary/Hard",
		element: <Default name="Hard Summary"><HardSummary /></Default>,
	},
	{
		path: "/Summary/Farmable",
		element: <Default name="Farmable Summary"><FarmableSummary /></Default>,
	},
	{
		path: "/Tool/SparkAnalytics",
		element: <Default name="Spark Analytics"><SparkRate /></Default>,
	},
	// {
	// 	path: "/Fun/BaSort",
	// 	element: <Default name="Blue Archive Sorter"><BaSort /></Default>,
	// },
	{
		path: "/Fun/Kivodle",
		element: <Default name="Kivodle"><Kivodle /></Default>,
	}
]);

async function initialize() {
	await new StudentMap().initialize();
}

initialize().then(
	() => {
		root.render(
			<RouterProvider router={router}/>
		);
	}
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();