import { RAID_MINSCORE } from "Constants/Common";

export function ciEquals(a, b) {
    return typeof a === 'string' && typeof b === 'string'
        ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
        : a === b;
}
export function ciContains(parent, child) {
    return typeof parent === 'string' && typeof child === 'string'
        ? parent.toLocaleLowerCase().indexOf(child.toLocaleLowerCase()) >= 0
        : parent === child;
}
export function padLeadingZero(num, len) {
	return String(num).padStart(len, '0');
}
const aprilFools = {
	month: 3,
	date: 1
}
  
export function isAprilFools() {
	const now = new Date();
	return (now.getMonth() == aprilFools.month && now.getDate() == aprilFools.date);
}

export const copyToClipboard = async ( valueToPass:string ) => {
try {
	if (!navigator.clipboard) {
		throw new Error("Browser don't have support for native clipboard.");
	}

	await navigator.clipboard.writeText(valueToPass ?? "");
} catch (error) {
	console.log(error.toString());
}
};
  
  
export function getDifficultyNumber(score:number) {
	if (score >= RAID_MINSCORE.TOR)
		return 6;
	if (score >= RAID_MINSCORE.INS)
		return 5;
	if (score >= RAID_MINSCORE.EXT)
		return 4;
	if (score >= RAID_MINSCORE.HC)
		return 3;
	if (score >= RAID_MINSCORE.VH)
		return 2;
	if (score >= RAID_MINSCORE.H)
		return 1;
	return 0;
}

export function secondsToTime(seconds:number) {
	const isNegative = seconds < 0;
	seconds = Math.abs(seconds);
	let prefix = isNegative ? "-" : "";
	if (seconds > 3600) {
		const hr = Math.floor(seconds/3600);
		return `${prefix}${hr}:${padLeadingZero(Math.floor( (seconds%3600)/60 ), 2)}:${padLeadingZero(Math.floor(seconds%60), 2)}.${(seconds%1).toLocaleString(undefined, { minimumFractionDigits: 3 }).substring(2)}`;
	}
	
	return `${prefix}${padLeadingZero(Math.floor( (seconds%3600)/60 ), 2)}:${padLeadingZero(Math.floor(seconds%60), 2)}.${(seconds%1).toLocaleString(undefined, { minimumFractionDigits: 3 }).substring(2)}`;
}
export function reduceSum(a:number, b:number) {
	return a+b;
}
export function reduceInitials(a:string,b:string) {
	return a+b[0];
}
export async function getJson(url:string) {
	try {
		const rawResponse = await fetch(url, {
			method: 'GET'
		});
		const data = await rawResponse.json();
		return data;
	} catch (e) {
		return {};
	}
}

export async function getText(url:string) {
	try {
		const rawResponse = await fetch(url, {
			method: 'GET'
		});
		const data = await rawResponse.text();
		return data;
	} catch (e) {
		return "";
	}
}
export function focusTextBox(id: string) {
	const dom = document.getElementById(id);
	dom.focus();
	(dom as HTMLInputElement).select();
}

export function sortByLevenshteinDistance(arr: string[], target: string): string[] {
    return arr.sort((a, b) => {
        const distA = levenshteinDistance(a, target);
        const distB = levenshteinDistance(b, target);
        return distA - distB;
    });
}

export function levenshteinDistance(a: string, b: string): number {
    const matrix: number[][] = [];

    // Create empty matrix
    for (let i = 0; i <= b.length; i++) {
        matrix[i] = [];
        matrix[i][0] = i;
    }
    for (let j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    // Fill the matrix
    for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
            if (b[i - 1] === a[j - 1]) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j - 1] + 1, // substitution
                    matrix[i][j - 1] + 1, // insertion
                    matrix[i - 1][j] + 1 // deletion
                );
            }
        }
    }

    return matrix[b.length][a.length];
}
export function toSingleLine(str):string {
    return str.replaceAll(/\n/g, '/').replaceAll(/\s*(\/\s*)+/g, ' / ');
}

export function multiLineSplice(str):string[] {
    return str.replaceAll(/\n/g, '/').replaceAll(/\s*(\/\s*)+/g, ' / ').split(' / ');
}

export const getThumbnailUrl = (student) => {
	// =CONCATENATE("https://justin163.com/planner/icons/Portrait/Icon_", B112, ".png")
	if (STUDENT_ID[student]) 
		return `https://justin163.com/planner/icons/Portrait/Icon_${STUDENT_ID[student]}.png`;
	
	return 'https://cdn.discordapp.com/attachments/417656014997815299/1082322867515043971/image.png';
}

export const STUDENT_ID = {
	'Aru': 10000,
	'Eimi': 10001,
	'Haruna': 10002,
	'Hifumi': 10003,
	'Hina': 10004,
	'Hoshino': 10005,
	'Iori': 10006,
	'Maki': 10007,
	'Neru': 10008,
	'Izumi': 10009,
	'Shiroko': 10010,
	'Shun': 10011,
	'Sumire': 10012,
	'Tsurugi': 10013,
	'Izuna': 10014,
	'Aris': 10015,
	'Midori': 10016,
	'Cherino': 10017,
	'Yuzu': 10018,
	'Azusa': 10019,
	'Koharu': 10020,
	'Azusa (Swimsuit)': 10021,
	'Hina (Swimsuit)': 10022,
	'Iori (Swimsuit)': 10023,
	'Shiroko (Cycling)': 10024,
	'Shun (Small)': 10025,
	'Neru (Bunny)': 10026,
	'Karin (Bunny)': 10027,
	'Asuna (Bunny)': 10028,
	'Natsu': 10029,
	'Chinatsu (Hot Spring)': 10030,
	'Aru (New Year)': 10031,
	'Mutsuki (New Year)': 10032,
	'Wakamo': 10033,
	'Mimori': 10034,
	'Ui': 10035,
	'Hinata': 10036,
	'Marina': 10037,
	'Miyako': 10038,
	'Miyu': 10039,
	'Tsukuyo': 10040,
	'Misaki': 10041,
	'Atsuko': 10042,
	'Wakamo (Swimsuit)': 10043,
	'Nonomi (Swimsuit)': 10044,
	'Hoshino (Swimsuit)': 10045,
	'Izuna (Swimsuit)': 10046,
	'Chise (Swimsuit)': 10047,
	'Saori': 10048,
	'Kazusa': 10049,
	'Akane': 13000,
	'Chise': 13001,
	'Akari': 13002,
	'Hasumi': 13003,
	'Nonomi': 13004,
	'Kayoko': 13005,
	'Mutsuki': 13006,
	'Junko': 13007,
	'Serika': 13008,
	'Tsubaki': 13009,
	'Yuuka': 13010,
	'Momoi': 13011,
	'Kirino': 13012,
	'Haruka': 16000,
	'Asuna': 16001,
	'Kotori': 16002,
	'Suzumi': 16003,
	'Pina': 16004,
	'Tsurugi (Swimsuit)': 16005,
	'Izumi (Swimsuit)': 16006,
	'Tomoe': 16007,
	'Fubuki': 16008,
	'Michiru': 16009,
	'Hibiki': 20000,
	'Karin': 20001,
	'Saya': 20002,
	'Mashiro': 20003,
	'Mashiro (Swimsuit)': 20004,
	'Hifumi (Swimsuit)': 20005,
	'Saya (Casual)': 20006,
	'Hatsune Miku': 20007,
	'Ako': 20008,
	'Cherino (Hot Spring)': 20009,
	'Nodoka (Hot Spring)': 20010,
	'Serika (New Year)': 20011,
	'Sena': 20012,
	'Chihiro': 20013,
	'Saki': 20014,
	'Kaede': 20015,
	'Iroha': 20016,
	'Hiyori': 20017,
	'Moe': 20018,
	'Airi': 23000,
	'Fuuka': 23001,
	'Hanae': 23002,
	'Hare': 23003,
	'Utaha': 23004,
	'Ayane': 23005,
	'Shizuko': 23006,
	'Hanako': 23007,
	'Mari': 23008,
	'Chinatsu': 26000,
	'Kotama': 26001,
	'Juri': 26002,
	'Serina': 26003,
	'Shimiko': 26004,
	'Yoshimi': 26005,
	'Nodoka': 26006,
	'Ayane (Swimsuit)': 26007,
	'Shizuko (Swimsuit)': 26008,
	'Kokona': 10050,
	'Hibiki (Cheering)': 16010,
	'Utaha (Cheering)': 10051,
	'Noa': 10052,
	'Akane (Bunny)': 20019,
	'Yuuka (Tracksuit)': 10053,
	'Mari (Tracksuit)': 10054,
	'Himari': 20020,
	'Shigure': 10055,
	'Hasumi (Tracksuit)': 16011,
	'Serina (Christmas)': 10056,
	'Hanae (Christmas)': 20021,
	'Haruna (New Year)': 10057,
	'Fuuka (New Year)': 20022,
	'Junko (New Year)': 16012,
	'Mine': 10058,
	'Mika': 10059,
	'Megu': 10060,
	'Kanna': 20023,
	'Sakurako': 10061,
	'Nagisa': 20024,
	'Toki': 10062,
	'Koyuki': 10063,
	'Kayoko (New Year)': 10064,
	'Haruka (New Year)': 20025,
}