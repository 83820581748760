import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";


export default function MessageBody() {
	return (<>
		<Row style={{
			margin: "0.5em",
			padding: "0.5em"
		}}>
			<Col>
				<h1 className="text-pink">Tower Updates </h1>
				<ul>
					<li>Addeed support for filters -- Include and Exclude Students</li>
					<li>Implemented Analytics</li>
				</ul>
			</Col>
		</Row>
		<Row style={{
			margin: "0.5em",
			padding: "0.5em"
		}}>
			<Col>
				<h1 className="text-pink">Hina Loves Midokuni Updates </h1>
				<ul>
					<li>Removed Total/Grand Assault Season Quicklinks from NavBar since its annoying to maintain. Look for the seasons in the Server pages instead</li>
					<li>Added support for <span className="text-pink">some titles</span>. You can now search for Bond and Limit Break titles in the Raid leaderboards when the data allows.</li>
					<li>Cleared backlogs of data for leaderboards.</li>
				</ul>
			</Col>
		</Row>
		<Row style={{
			margin: "0.5em",
			padding: "0.5em"
		}}>
			<Col>
				<h1 className="text-pink"><Link to="/Resources">Blue Archive Repository of Resources, Guides, Tools, etc.</Link></h1>
				<p><Link to="/Resources">Blue Archive Repository of Resources, Guides, Tools, etc.</Link></p>
				<p>This is a database of various resources, guides, tools, channels, theses, charts, and many more. 
					The contents of this Resources page is maintained and updated by the community.</p>
			</Col>
		</Row>
		<Row style={{
			margin: "0.5em",
			padding: "0.5em"
		}}>
			<Col>
				<h1 className="text-pink">Tower</h1>
				Currently under development: <Link to="/Tower/JP/2">Tower / Limit Break Assault / Unleashed Assault Teams Data</Link>
				<br></br>
				<br></br>
				I plan to make this similar to Total Assault Leaderboard and Grand Assault Leaderboard.
			</Col>
		</Row>
		<Row style={{
			margin: "0.5em",
			padding: "0.5em"
		}}>
			<Col>
				<h1 className="text-pink">Hina Loves Midokuni</h1>
				<ul>
					<li>Added <Link to="/Summary/Hard">Hards Summary (Web)</Link></li>
					<li>Added <Link to="/Summary/Hard">Farmable Summary (Web)</Link></li>
					<li>Updated Tab display of <Link to="/StudentInsights">Student Insights</Link></li>
					<li>Added stuff to Summary pages</li>
					<li>Hina Links</li>
				</ul>

			</Col>
		</Row>
		<Row style={{
			margin: "0.5em",
			padding: "0.5em"
		}}>
			<Col>
				<h1 className="text-pink">Korea Server</h1>
				Someone offered to give Korea Total/Grand Assault data starting TAS55 so I will now be adding them as well.
			</Col>
		</Row>
		<Row style={{
			margin: "0.5em",
			padding: "0.5em"
		}}>
			<Col>
				<h1 className="text-pink">Home</h1>
				I added a Home page. Now you can click that brand logo/name.
			</Col>
		</Row>
		<Row style={{
			margin: "0.5em",
			padding: "0.5em"
		}}>
			<Col>
				<h1 className="text-pink">Hina Loves Midokuni</h1>
				Hi guys, I'll be starving myself thanks to all the Hina merch and packages.
				<br></br>Feed me Ko-fi: <Link to="https://ko-fi.com/midokuni">https://ko-fi.com/midokuni</Link>
				<br></br>Much appreciated!
				<br></br>
				<br></br> - Midokuni, about to die.
				<br></br>
				<br></br>Update: Sadly back home. Global data should be complete but Japan data may have missing seasons.
			</Col>
		</Row>
		<Row style={{
			margin: "0.5em",
			padding: "0.5em"
		}}>
			<Col>
				<h1 className="text-pink">Activity Notice</h1>
				I have a combination of intense digital farming sessions and a long trip to Japan so please do <span className="text-pink">not</span> expect any feature and writeup updates from me at all until <span className="text-pink">end of 2024 Feb</span>.
				<br></br> Total and Grand Assault data should still continue but do not expect much as they might be delayed.
			</Col>
		</Row>
		<Row style={{
			margin: "0.5em",
			padding: "0.5em"
		}}>
			<Col>
				<h2 className="text-pink">Grand Assault JP S8</h2>
				I actually have full data until top 20k but cloudflare wont let me upload the entire thing due to file size limit soo... ThatI'll figure something out in the future.
			</Col>
		</Row>
		<Row style={{
			margin: "0.5em",
			padding: "0.5em"
		}}>
			<Col>
				<h2 className="text-pink">Update</h2>
				Implemented Barebones <span className="text-pink">Board Runner Event Simulators</span> (Yuuka/Saki). Can be found in Tools dropdown.
				<br></br> Barebones for now so no fancy stuff but I did try to make it functional with extra features.
				<br></br> Will implement custom boards in the future... How soon IDK but I made the code to be able to support it. I just need to create the UI for it.
			</Col>
		</Row>

		<Row style={{
			margin: "0.5em",
			padding: "0.5em"
		}}>
			<Col>
				<h2 className="text-pink">Minor Update</h2>
				Made Advanced Student Options be <span className="text-pink">enabled by default</span> in Total Assault filters because too many people don't even read and not know how to use advanced filters. I've made it remember your previous state so it won't always be on at the start.
			</Col>
		</Row>
		<Row style={{
			margin: "0.5em",
			padding: "0.5em"
		}}>
			<Col>
				<h2 className="text-pink">Update</h2>
				Added <a href="/Tool/Spark">Spark Tracker</a> under Tools for your screenshot needs.
			</Col>
		</Row>
		<Row style={{
			margin: "0.5em",
			padding: "0.5em"
		}}>
			<Col>
				<h1 className="text-pink">White Screen of Death</h1>
				If you have friends who are getting White Screens when trying to access the website, kindly recommend them to update their browsers as some features of the website require the later features of more recent browsers to work properly.
				<br></br>
				<br></br>
				I'm not too keen on identifying, isolating, and fixing what exactly is causing these white screens of death but I <em>think</em> these should be the minimum Browser versions:
				<ul>
					<li>Chrome: v62+</li>
					<li>Edge: v79+</li>
					<li>Safari: v16.4+</li>
					<li>Firefox: v78+</li>
					<li>Opera: v49+</li>
					<li>IE: Never</li>
					<li>Chrome/Firefox (Android): v119+</li>
					<li>Safari (iOS): v16.4+</li>
					<li>Samsung internet: 8.2+</li>
					<li>Opera (Mini): All</li>
					<li>Opera (Mobile): v73+</li>
				</ul>
				If you see this message, that means you don't have any issues running the website.
				<br></br>
				<br></br>
				<small>Refer to your browser's help section to find out how to update or check your browser version.</small>
			</Col>
		</Row>
	</>)
}