import { Sticky } from "Components/Sticky";
import { StudentDetails } from "Components/StudentDetails";
import { StudentInfoCard } from "Components/StudentInfoCard";
import { StudentInfoSheet } from "Components/StudentInfoSheet";
import { StudentPicker } from 'Components/StudentPicker';
import { BRICC_ARCHIVE_SPREADSHEET_GID, BRICC_ARCHIVE_SPREADSHEET_ID, GOOGLE_SPREADSHEET_GID, GOOGLE_SPREADSHEET_ID, SPACE_REGEX, STOKKIE_RAID_SPREADSHEET_GID, STOKKIE_RAID_SPREADSHEET_ID } from "Constants/Common";
import { ISheetDatabase } from "Interfaces/ISheetDatabase";
import BannerStudent from "Models/BannerStudent";
import BorrowStudent from "Models/BorrowStudent";
import FarmableStudent from "Models/FarmableStudent";
import Sheet from 'Models/Sheet';
import StudentMap from 'Models/StudentMap';
import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
// TODO: Redesign to be able to use multiple in one page
interface IProps {
	staticStudent?: string;
}
export default function StudentInsights(props: IProps) {
	const { staticStudent } = props;
	const [student, setStudent] = useState(staticStudent ?? "Hina");
	const [searchQuery, setSearchQuery] = useState(staticStudent ?? "Hina");
	const [database, setDatabase] = useState<ISheetDatabase>(null)
	const { studentParam } = useParams()

	const setSearch = (data:string) => {
		if (!!staticStudent) return;
		const s = new StudentMap().getProperName(data);
		if (s) {
			setSearchQuery(s);
			setStudent(s);
		} else {
			setSearchQuery(data);
		}
	}

	const loadAsync = async () => {
		const banner = new Sheet(GOOGLE_SPREADSHEET_ID, GOOGLE_SPREADSHEET_GID.BANNER);
		const bannerTask = banner.initialize();
		const farmable = new Sheet(GOOGLE_SPREADSHEET_ID, GOOGLE_SPREADSHEET_GID.FARMABLE);
		const farmableTask = farmable.initialize();
		// const students = new Sheet(GOOGLE_SPREADSHEET_ID, GOOGLE_SPREADSHEET_GID.STUDENTS);
		// const studentsTask = students.initialize();
		const b = new Sheet(GOOGLE_SPREADSHEET_ID, GOOGLE_SPREADSHEET_GID.BORROW);
		const bTask = b.initialize();

		const thoughts = new Sheet(GOOGLE_SPREADSHEET_ID, GOOGLE_SPREADSHEET_GID.THOUGHTS);
		const thoughtsTask = thoughts.initialize();

		
		const stk1Task = new Sheet(STOKKIE_RAID_SPREADSHEET_ID, STOKKIE_RAID_SPREADSHEET_GID.STRIKER_EXT).initialize();
		const stk2Task = new Sheet(STOKKIE_RAID_SPREADSHEET_ID, STOKKIE_RAID_SPREADSHEET_GID.SPECIAL_EXT).initialize();
		const stk3Task = new Sheet(STOKKIE_RAID_SPREADSHEET_ID, STOKKIE_RAID_SPREADSHEET_GID.STRIKER_INS).initialize();
		const stk4Task = new Sheet(STOKKIE_RAID_SPREADSHEET_ID, STOKKIE_RAID_SPREADSHEET_GID.SPECIAL_INS).initialize();
		const cswT50Task = new Sheet(STOKKIE_RAID_SPREADSHEET_ID, STOKKIE_RAID_SPREADSHEET_GID.TOWER_F50).initialize();
		const bricc1 = new Sheet(BRICC_ARCHIVE_SPREADSHEET_ID, BRICC_ARCHIVE_SPREADSHEET_GID.STRIKER).initialize();
		const bricc2 = new Sheet(BRICC_ARCHIVE_SPREADSHEET_ID, BRICC_ARCHIVE_SPREADSHEET_GID.SPECIAL).initialize();

		const studentMap = new StudentMap();
		if (!!staticStudent) {
			// do nothing
		} else if (studentParam) {
			const s = studentParam.replaceAll(SPACE_REGEX," ");
			setSearch(s);
			// setSearchQuery(s);
			// setStudent(s);
		} else {
			const url: URL = new URL(window.location.href);
			const params: URLSearchParams = url.searchParams;
			if (params.has('student'))
				setStudent(params.get('student').replaceAll(SPACE_REGEX," "));
		}
		// studentMap
			// .populateId(await studentsTask)
			// .populateAlias(await thoughtsTask)

		setDatabase({
			banner: await bannerTask,
			farmable: await farmableTask,
			students: studentMap,
			borrow: await bTask,
			thoughts: await thoughtsTask,
			generics: {
				cswTower: await cswT50Task,
				stokRaidRating: {
					STRIKER_EXT: await stk1Task,
					SPECIAL_EXT: await stk2Task,
					STRIKER_INS: await stk3Task,
					SPECIAL_INS: await stk4Task,
				},
				briccArchive: {
					STRIKER: await bricc1,
					SPECIAL: await bricc2,
				}
			}
		});
		
		BorrowStudent.setUpdatedAt(b.getTable()?.rows[0]?.c[0].f ?? '');
	}

	useEffect(() => { loadAsync() }, []);

	return (
		<>
			<Row className="bg-default">
				<Col style={{
					zIndex: "99999999999999999999"
				}}>
				<StudentPicker 
					visible={!!!staticStudent}
					autoFocus={true} 
					target={searchQuery} 
					setTarget={setSearch} 
				/>
				</Col>
			</Row>
			<h2>Student Info</h2>
			<Row>
				<Row>
					<Col xs={12} sm={5} md={4} lg={3}>
						<Sticky offset={70}>
							<StudentDetails target={student} database={database}></StudentDetails>
						</Sticky>
					</Col>
					<Col style={{marginBottom:10}}>
						<StudentInfoCard target={student} database={database}/>
					</Col>
				</Row>

				<Stack style={{marginTop: 10}}>
					<StudentInfoSheet 
						name="Banner"
						dataSource={database?.banner}
						target={student}
						template={new BannerStudent()}
					/>
					<StudentInfoSheet
						name="Farmable"
						dataSource={database?.farmable}
						target={student}
						template={new FarmableStudent()}
					/>
					<StudentInfoSheet
						name="Borrow"
						nameCol="B"
						dataSource={database?.borrow}
						target={student}
						template={new BorrowStudent()}
					/>
				</Stack >
				{/* <StudentInfo target={student} database={database}/> */}
			</Row>
		</>
	)
}