import { Images } from "Components/Images";
import IStyle from "Interfaces/IStyle";
import React from "react";
import { Col, Row } from "react-bootstrap";
interface IProps {
	isLoading: boolean;
	size?: string | number;
	text?: string;
}
const styles:IStyle = {
	container: {
		// backgroundColor: "white",
		// backgr
		// textAlign: "center",
		// alignContent: "center",
		// alignItems: "center",
	}
}

export default function MyLoadingSpinner({isLoading, size, text}: IProps) {
	if (!isLoading) return null;
	return (
		<span style={styles.container} className="my-loading">
			<Row style={{height: size ?? 512}}>
				<Col><Images.Spinner size={size}/></Col>
			</Row>
			<Row>
				<Col><span className="text-pink">{text ?? "Loading..."}</span></Col>
			</Row>
		</span>
	)
}