import { IIconButtonProps } from "Interfaces/Props/IIconButtonProps";
import React from "react";
import { Button, Col, Figure, Row } from "react-bootstrap";

export default class IconButton extends React.Component<IIconButtonProps> {
	render() {
		return (
			<Button 
				style={{width:"100%"}} 
				href={this.props.href} 
				target="_blank"
				onClick={this.props.onClick}
				variant='outline-secondary'
			>
				<Row>
					<Col>
						<Figure.Image
							width={this.props.width ?? this.props.height ?? 30}
							height={this.props.height ?? this.props.width ?? 30}
							src={this.props.image}>
						</Figure.Image>
					</Col>
				</Row>
				<Row>
					<Col>
						{this.props.label}
					</Col>
				</Row>
			</Button>
		)
	}
}
