import { TagBadge } from "Components/TagBadge";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function About() {
	return (
		<>
			<Row>
				<Col><h1>About Me</h1></Col>
			</Row>
			<Row>
				<Col>Should actually be Contact <span className="text-pink">Us</span> but I'm developing this alone. </Col>
			</Row>
			<Row>
				<Col>I mainly made this /About just so I can test the fancy Navigation Bar above lol. </Col>
			</Row>
			<Row>
				<Col><h2>Social</h2></Col>
			</Row>
			<Row>
				<Col>Feel free to drop me a DM/Message on any of these. I'm mainly on Discord but note that if you only send a Friend Request I might end up ignoring you if you don't leave a message prior to sending the request.</Col>
			</Row>
			<Row>
				<Col>
					<ul>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>Discord</Col>
								<Col>midokuni</Col>
							</Row>
						</li>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>Youtube</Col>
								<Col><Link to="https://www.youtube.com/@Midokuni">@Midokuni</Link></Col>
							</Row>
						</li>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>X</Col>
								<Col><Link to="https://x.com/Midokuni_Mido">@Midokuni_Mido</Link></Col>
							</Row>
						</li>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>Reddit</Col>
								<Col><Link to="https://www.reddit.com/user/Midokuni">u/Midokuni</Link></Col>
							</Row>
						</li>
					</ul>
				</Col>
			</Row>
			<Row>
				<Col><h2>Email</h2></Col>
			</Row>
			<Row>
				<Col>
					<ul>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>Personal</Col>
								<Col>... <TagBadge>No</TagBadge></Col>
							</Row>
						</li>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>Work</Col>
								<Col>... Also <TagBadge>No</TagBadge></Col>
							</Row>
						</li>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>Dev</Col>
								<Col><a href="mailto:dev@midokuni.com">dev@midokuni.com</a></Col>
							</Row>
						</li>
					</ul>
				</Col>
			</Row>
			<Row>
				<Col><h2>Support</h2></Col>
			</Row>
			<Row>
				<Col>How kind of you to want to support me awwww. If you insist:</Col>
			</Row>
			<Row>
				<Col>
					<ul>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>Dev</Col>
								<Col>Tell me your thoughts/suggestions/reports on my Socials. 
								I am also in <Link to="https://discord.com/invite/bluearchiveglobal">Blue Archive Official(Global) Discord server.</Link></Col>
							</Row>
						</li>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>Ko-fi</Col>
								<Col><Link to="https://ko-fi.com/midokuni">https://ko-fi.com/midokuni</Link></Col>
							</Row>
						</li>
					</ul>
				</Col>
			</Row>
		</>
	)
}