import { Images } from "Components/Images";
import IParentProps from "Interfaces/Props/IParentProps";
import React from "react";
import { Badge, Image } from "react-bootstrap";

export class TagBadge extends React.Component<IParentProps & {pill?: boolean, pretext?: string, posttext?: string}> {
	render() {
		const {pill, pretext, posttext} = this.props;
		const child = this.props.children.toString();
		switch(child) {
			case "Hina in a Dress":
			case "Hina":
			case "Platinum":
				return (<Badge pill={pill} style={{marginRight: 5}} bg="violet">{this.props.children}</Badge>);
			case "Wait":
				return (<Badge pill={pill} style={{marginRight: 5}} bg="dull-violet">{this.props.children}</Badge>);
			case "No":
			case "Skip":
				return (<Badge pill={pill} style={{marginRight: 5}} bg="danger">{this.props.children}</Badge>);
			case "Anytime":
			case "Limited":
				return (<Badge pill={pill} style={{marginRight: 5}}>{this.props.children}</Badge>);
			case "Fes Limited":
			case "Almost Must Get":
			case "Always":
			case "Enabler":
			case "Must Get":
				return (<Badge pill={pill} style={{marginRight: 5}} bg="success">{this.props.children}</Badge>);
			case "A":
				return (<Badge style={{padding: "0.3em"}} bg="primary">{this.props.children}</Badge>);
			case "UE":
				return (<Badge style={{marginRight: 5}} pill bg="primary"><Images.UE />{pretext}{this.props.children}</Badge>);
			case "Gold":
				return (<Badge pill={pill} style={{marginRight: 5}} bg="dull-yellow">{this.props.children}</Badge>);
			case "Bronze":
				// TODO: Make a badge for Bronze
				return (<Badge pill={pill} style={{marginRight: 5}} bg="danger">{this.props.children}</Badge>);
			case "Explosive":
			case "explosive":
				return (<Badge pill={pill} bg="danger">Explosive</Badge>);
			case "Light":
			case "light":
				return (<Badge pill={pill} bg="danger">Light</Badge>);
				// return (<><Image style={{height:18, marginRight: -2}} src="/img/RedArmor.png"></Image><Badge pill={pill} style={{marginRight: 5}} bg="danger">{this.props.children}</Badge></>);
				// return (<><Image style={{height:18, marginRight: 1, marginTop: -2}} src="/img/RedArmor.png"></Image>{this.props.children}</>);
			case "Heavy":
			case "heavy":
				return (<Badge pill={pill} bg="warning">Heavy</Badge>);
				// return (<><Image style={{height:18, marginRight: -2}} src="/img/YellowArmor.png"></Image><Badge pill={pill} style={{marginRight: 5}} bg="warning">{this.props.children}</Badge></>);
				// return (<><Image style={{height:18, marginRight: 1, marginTop: -2}} src="/img/YellowArmor.png"></Image>{this.props.children}</>);
			case "Piercing":
			case "piercing":
				return (<Badge pill={pill} bg="warning">Piercing</Badge>);
			case "Mystic":
			case "mystic":
				return (<Badge pill={pill} bg="primary">Mystic</Badge>);
			case "Special":
			case "special":
				return (<Badge pill={pill} bg="primary">Special</Badge>);
				// return (<><Image style={{height:18, marginRight: -2}} src="/img/BlueArmor.png"></Image><Badge pill={pill} style={{marginRight: 5}} bg="primary">{this.props.children}</Badge></>);
				// return (<><Image style={{height:18, marginRight: 1, marginTop: -2}} src="/img/BlueArmor.png"></Image>{this.props.children}</>);
			case "Elastic":
			case "elastic":
				return (<Badge pill={pill} bg="violet">Elastic</Badge>);
				// return (<><Image style={{height:18, marginRight: -2}} src="/img/PurpleArmor.png"></Image><Badge pill={pill} style={{marginRight: 5}} bg="violet">{this.props.children}</Badge></>);
				// return (<><Image style={{height:18, marginRight: 1, marginTop: -2}} src="/img/PurpleArmor.png"></Image>{this.props.children}</>);
			case "Sonic":
			case "sonic":
				return (<Badge pill={pill} bg="violet">Sonic</Badge>);
		
			case "Outdoors":
			case "Field":
				return (<Badge pill={pill} style={{marginRight: 5}} bg="dull-yellow"><Image style={{height:18, marginRight: 2, position:"relative", top:-2}} src="/img/Terrain_Outdoor.png"></Image>{this.props.children}</Badge>);
			case "Indoor":
			case "Indoors":
				return (<Badge pill={pill} style={{marginRight: 5}} bg="primary"><Image style={{height:18, marginRight: 2, position:"relative", top:-2}} src="/img/Terrain_Indoor.png"></Image>{this.props.children}</Badge>);
			case "Street":
			case "Urban":
				return (<Badge pill={pill} style={{marginRight: 5}} bg="violet"><Image style={{height:18, marginRight: 2, position:"relative", top:-2}} src="/img/Terrain_Street.png"></Image>{this.props.children}</Badge>);
			case "When you simp":
			case "TOR":
				return (<Badge style={{marginRight: 5}} pill bg="danger">{this.props.children}</Badge>);
			case "INS":
			case "EXT":
				return (<Badge style={{marginRight: 5}} pill bg="primary">{this.props.children}</Badge>);
			case "HC":
			case "VH":
			case "H":
			case "N":
				return (<Badge style={{marginRight: 5}} pill bg="dark">{this.props.children}</Badge>);
			default:
				if (child.match(/(\d+[\*☆]?\s*)?UE\s*\d+[\*☆]/gi))
					return (<Badge style={{marginRight: 5}} pill bg="primary"><Image style={{height:18, marginRight: 2, position:"relative", top:-2}} src="/img/Common_Icon_CharacterWeapon_off.png"></Image>{this.props.children}</Badge>);
				return (<Badge pill={pill} style={{marginRight: 5}} bg="secondary">{this.props.children}</Badge>);
		}
	}
}
