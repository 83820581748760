import MyBrand from "Components/MyBrand";
import IStyle from "Interfaces/IStyle";
import React from "react";
import { Col, Figure, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const styles: IStyle = {
	pipe: {
		marginLeft: "0.6em",
		marginRight: "0.6em",
		// fontSize: "1.2em"
	},
	icon: {
		marginLeft: "0.2em",
		marginRight: "0.2em",
		width:"1.5em",
	}
}

export default function MyBrandFooter() {
	return (<Row style={{marginTop: 10}}>
		<Col>
			<Row><Col><MyBrand /></Col></Row>
			<Row style={{textAlign: "center", marginTop: "0.2em"}}>
				<Col>
					<sub>
						<span>
							<Figure.Image alt="Discord" style={styles.icon} src="https://www.google.com/s2/favicons?sz=64&domain=discord.com"></Figure.Image>
							midokuni
						</span>
						<span className="text-pink" style={styles.pipe}>●</span>
						<span>
							<Figure.Image alt="X (Formerly Twitter)" style={styles.icon} src="https://www.google.com/s2/favicons?sz=64&domain=x.com"></Figure.Image>
							<Link target="_blank" to="https://x.com/Midokuni_Mido">@Midokuni_Mido</Link>
						</span>
						<span className="text-pink" style={styles.pipe}>●</span>
						<span>
							<Figure.Image alt="Ko-Fi" style={styles.icon} src="https://www.google.com/s2/favicons?sz=64&domain=ko-fi.com"></Figure.Image>
							Feed me <Link target="_blank" to="https://ko-fi.com/midokuni">Ko-fi</Link>
						</span>
					</sub>
				</Col>	
			</Row>
			<Row style={{textAlign: "center", marginTop: "0.1em"}}>
				<Col>
					<sub>
						I am related to neither Nexon, Yostar nor any of their affiliates. I am just a simple fan doing this as a hobby.
					</sub>
				</Col>	
			</Row>
		</Col>	
	</Row>
)
}